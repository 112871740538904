@media screen {
  .containerInvoice {
    display: none;
  }
}

@media only print {
  
  .containerInvoice {
    display: block;
   // width: 9.5in;
    margin-right: 0;
    padding-right: 0;
  }
    .invoice_brand_row {
      display: flex;
      flex-direction: row;
    }
  
    .invoice_brand_logo {
       width: 100px;
       height: 100px;
    }

    .invoice_logo_smaller {
       width: 70px;
       height: 70px;
    }
    
    .invoice_brand_name {
      margin-bottom: 2px;
      margin-left: 10px;
      font-size: 18px;
    }
    
    .brand_name_small {
      margin-bottom: .7px;
      margin-left: 7px;
      font-size: 14px;
    }
  
    .invoice_rnc {
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      margin-top: 1px;
      margin-bottom: 1px;
    }
    
    .rnc_small {
      font-size: 9.5px;
      font-weight: bold;
      text-align: center;
      margin-top: .6px;
      margin-bottom: .6px;
    }

    .invoice_date {
      text-align:right;
      width: 100%;
    }

    .date_small {
      text-align:right;
      width: 100%;
      font-size: 12px;
      margin-bottom: 2px;
    }
  
    .invoice_label {
      font-weight: 500;
    }
  
    .invoice_status, .status_small {
      width: 100%;
      text-align: center;
      font-size: 28px;
      font-weight: bold;
      border-bottom: 1px solid #bdbdbd;
    }

    .status_small {
      font-size: 20px;
      margin-top: 5px;
    }

    .invoice_inner_status {
      display: flex;
      justify-content: space-between;
    }
    .invoice_credit {
      font-weight: 500;
      margin-bottom: 4px;
      margin-top: 4px;
      background-color: #898989;
      border: 1.2px solid ;
      font-size: 17px;
      color: white;
    }
    .invoice_register_number_description {
      font-weight: bold;
      margin-top: 4px;
      margin-bottom: 2px; 
    }
    .invoice_register_number_information {
      font-style: italic;
      margin-top: 0px;
      margin-bottom: 4px;
    }

    .payment_type_description_small {
      font-style: italic;
      text-align: end;
      margin-top: 2px;
      margin-bottom: 4px;
    }

    .text_bank_account {
      margin: 0;
      font-size: 14.5px;
    }
    .invoice_num {
      font-weight: bold;
      text-align: right;
    }

    .invoice_num_small {
      font-weight: bold;
      text-align: right;
      font-size: 12px;
      margin-top: 2.5px;
    }

    .invoice_client_container {
      margin-bottom: 5px;
    }

    .client_container_small {
      margin-bottom: 10px;
    }

    .invoice_format__full_name {
      margin: 4px 0;
      font-size: 20px;
    }

    .format__full_name_small {
      margin-top: 11px; 
      margin-bottom: 0;
      font-size: 18px;
    }
  
    .invoice_format__identifier {
      margin: 0;
      font-size: 13px;
    }

    .invoice_format__address {
      margin: 0;
      margin-bottom: 2px;
      font-size: 13px;
    }
  
    .invoice_format__detail_title {
      text-align: center;
      font-size: 15px;
      margin-top: 6px;
      margin-bottom: 4px;
    }
  
    .invoice_format__services_header {
      margin-top: 5px;
      border-bottom: 1px solid black;
      display: flex;
      flex-direction: row;
    }
  
    .invoice_format__service_title {
      font-weight: bold;
    }
  
    .invoice_format__services_body {
      display: flex;
      flex-direction: column;
    }
  
    .invoice_format__row {
      display: flex;
      justify-content: row;
      margin-top: 5px;
      margin-bottom: 4px;
    }
  
    .text_base {
      font-size: 12px;
    }

    .invoice_format__description {
      width: 580px;
      margin: 0 1px;
    }
  
    .invoice_format__quantity {
      width: 110px;
      margin: 0 1px;
    }
  
    .invoice_format__price,
    .invoice_format__total {
      width: 160px;
      margin: 0 1px;
    }
  
    .invoice_payment_type_description {
      font-style: italic;
      text-align: end;
    }

    .invoice_format__member_reference {
      margin-top: 20px;
      border-top: 2px solid #cfcfc4;
    }

    .invoice_format__member_reference_title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 15px;
    }

    .invoice_format_service_label {
      font-weight: bold;
      font-size: 13px;
      margin-left: 7px;
      margin-bottom: 5px;
      margin-top: 6px;
    }

    .invoice_format_member_label {
      font-size: 12px;
      margin-left: 15px;
      margin-top: 4px;
      margin-bottom: 4px;
    }

  }
  
  .invoice_format__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  //  justify-content: flex-end;
  }
  
  .invoice_total_container {
    display: flex;
    flex-direction: row;
  }
  
  .invoice_total_description {
    margin-right: 2px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .invoice_format_total_price {
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 7px;
    font-weight: 400;
    font-size: 15px;
  }
  
  .invoice_format__net_total {
    margin-top: 1px;
    padding: 7px;
    border: 1px solid #e0e0e0;
    font-weight: bold;
    font-size: 16px;
  }
  